import { Module } from '@ay-gosu-party/modules';

// 重新計算每一個 module 下的 filmstrip index
// 回傳最後的 filmstrip index
export function recalcFilmstripIndex(modules: Module[]): number {
  let index = 0;

  modules.forEach((module, moduleIndex) => {
    const isStartModule = moduleIndex === 0;
    const isEndModule = moduleIndex === modules.length - 1;

    module.filmstrips.forEach((filmstrip, filmstripIndex) => {
      filmstrip.index = ++index;

      const isStartFilmstrip = filmstripIndex === 0;
      const isEndFilmstrip = filmstripIndex === module.filmstrips.length - 1;
      filmstrip.isEnd = isEndModule && isEndFilmstrip;
      filmstrip.isStart = isStartModule && isStartFilmstrip;
    });
  });

  return index;
}
